export { getLesson, initialInputs, gradeLesson, getLanguages };

async function getLesson(topics, topic) {
  let lesson;
  let rows;

  if (topic === "random") {
    let { file } = topics[Math.floor(Math.random() * topics.length)];
    topic = file;
  }

  await import(`../json/${topic}`).then((lessons) => {
    randomLesson(lessons);
  });

  function randomLesson(lessons) {
    let randLesson = lessons[Math.floor(Math.random() * lessons.length)];
    lesson = randLesson.title;
    rows = Object.entries(randLesson.rows);
  }

  return { lesson, rows };
}

function initialInputs(rows) {
  let inputs = {};

  for (let i = 0; i < rows.length; i++) {
    let property = rows[i][0];

    Object.defineProperty(inputs, property, {
      value: "",
      writable: true,
    });
  }

  return inputs;
}

function gradeLesson(inputs, answers, language) {
  let results = [];

  for (let i = 0; i < answers.length; i++) {
    var correct = isMatch(
      inputs[answers[i][0]].trim(),
      answers[i][1],
      language
    );

    if (!correct) {
      results.push({
        prop: answers[i][0],
        correct: true,
      });
    } else {
      results.push({
        prop: answers[i][0],
        correct: false,
        answer: answers[i][1],
      });
    }
  }

  return results;
}

function isMatch(source, target, language) {
  let result;

  if (Array.isArray(target)) {
    for (let i = 0; i < target.length; i++) {
      result = source.localeCompare(target[i], language, {
        sensitivity: "base",
      });

      if (result === 0) {
        break;
      }
    }
  } else {
    result = source.localeCompare(target, "es", {
      sensitivity: "base",
    });
  }

  return result;
}

function getLanguages() {}
