import { Link } from "react-router-dom";

function Footer() {
  const date = new Date();

  return (
    <footer>
      <p>Copyright ⓒ 2022 - {date.getFullYear()} LingoTable.com</p>
      <p>
        <Link to={"privacy"}>Privacy Policy</Link>
      </p>
    </footer>
  );
}

export default Footer;
