import React, { useState, createContext } from "react";
import MainPage from "./components/pages/MainPage";
import "./App.css";
import "bulma/css/bulma.min.css";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  connectAuthEmulator,
  onAuthStateChanged,
} from "firebase/auth";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Privacy from "./routes/Privacy";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAIiJm74U278r1K5z1M7llbW4A4FXSAnNw",
  authDomain: "lingotable-84389.firebaseapp.com",
  projectId: "lingotable-84389",
  storageBucket: "lingotable-84389.appspot.com",
  messagingSenderId: "363038997065",
  appId: "1:363038997065:web:6c99b1ff299ecde606dd98",
  measurementId: "G-RGE8J8JLBF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export let user;

if (document.location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099");
} else {
  getAnalytics(app);
}

export const UserContext = createContext();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState();

  user = onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // ...
      setIsLoggedIn(true);
      return user;
    } else {
      setIsLoggedIn(false);
      return false;
    }
  });

  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainPage />,
    },
    { path: "privacy", element: <Privacy /> },
  ]);

  return (
    <UserContext.Provider value={isLoggedIn}>
      <RouterProvider router={router} />
    </UserContext.Provider>
  );
}

export default App;
