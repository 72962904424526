function Main(props) {
  const data = props.content;

  return (
    <main>
      {data}
    </main>
  );
}

export default Main;
