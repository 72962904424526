import MainTemplate from "../templates/MainTemplate";
import "../../App.css";

function PrivacyPage() {
  const mainContent = (
    <div className="notification">
      <h1>PRIVACY POLICY</h1> <h2>Effective Date: 6/17/2023</h2> <br />
      <h3>Introduction.</h3>
      <br />
      <p>
        This Privacy Policy is meant to serve users of this website or app as a
        means to disclose how we collect, use, process, share, manage, and store
        your personal information and data.
      </p>
      <br />
      <h3>Contact Details.</h3> <br />
      <p>
        If, at any time, users have any questions about this Private Policy,
        they may send them by contacting us at lingotable@gmail.com
      </p>
      <br />
      <h3>Website Details.</h3> <br />
      <ul>
        <li>Website URL: https://www.lingotable.com</li>
        <li>Website Name: Lingo Table</li>
      </ul>
      <br />
      <h3>Mobile App.</h3>
      <br />
      <ul>
        <li>Mobile App Name: Lingo Table</li>
        <li>Available on: Android (Google Play Store)</li>
      </ul>
      <br />
      <h3>DISCLAIMERS.</h3>
      <br />
      <p>
        This Policy attempts to provide as much transparency as possible in
        regard to disclosing the amount of information on how we collect, use,
        and manage users' data.
      </p>
      <br />
      <h3>CATEGORIES OF PERSONAL INFORMATION.</h3>
      <br />
      <p>We DO collect the following:</p>
      <br />
      <ul>
        <li>
          <em>App Activity.</em> Activities related your browsing or usage, such
          as date and time of visit, approximate location origin of visit,
          device (web or mobile platform, OS, resolution, browser).
        </li>
      </ul>
      <br />
      <h3>ADVERTISEMENTS.</h3>
      <p>
        Both website and mobile applications do NOT Have Advertisements. We do
        not have display ads or any 3rd party paid affiliates.
      </p>
      <br />
      <h3>TARGET AUDIENCE AND CONTENT.</h3>
      <p>
        Both website and mobile applications are targeted for ages 13 and over.
        We provide educational Spanish language exercises on various grammar
        topics.
      </p>
      <br />
      <h3>HOW WE USE PERSONAL INFORMATION OR DATA.</h3>
      <br />
      <p>We may use your activity usage for the following purpose:</p>
      <br />
      <ul>
        <li>
          <em>Feedback.</em> To get feedback on website improvements and
          generally provide an overall better experience
        </li>
        <li>
          <em>Testing.</em> For testing, research, and analysis, of user
          behavior on the website or app.
        </li>
      </ul>
      <br />
      <h3>SHARING PERSONAL INFORMATION.</h3>
      <br />
      <p>
        We share the following to 3rd parties for business purposes. The general
        categories of 3rd parties that we share with are as follows:
      </p>
      <br />
      <ul>
        <li>
          <em>App Activity.</em> Activities related your browsing or usage, such
          as date and time of visit, approximate location origin of visit,
          device (web or mobile platform, OS, resolution, browser) are sent to
          Google Analytics for analytical purposes.
        </li>
      </ul>
      <br />
      <h3>CHANGES AND AMENDMENTS.</h3>
      <br />
      <p>
        We reserve the right to amend this Policy at our discretion and at any
        time. When we make changes to this Policy, we agree to notify you by
        e-mail or other preferred communication methods.
      </p>
      <br />
    </div>
  );

  return <MainTemplate main={mainContent} />;
}

export default PrivacyPage;
