import { useState, useEffect, useContext } from "react";
import { gradeLesson } from "../../functions/Table";
import { TableViewContext } from "./TableView";

function TableButtonView(props) {
  const [nextButton, setNextButton] = useState(false);

  const [setNextLesson, setGrades, language] = useContext(TableViewContext);

  useEffect(() => {
    setNextButton(false);
  }, [props.rows]);

  const finishButton = (
    <input
      type="submit"
      id="finishBtn"
      value="Finish"
      onClick={() => {
        let results = gradeLesson(props.inputs, props.rows, language);

        results.map((el) => <>el</>);
        props.setResults(true);
        setNextButton(true);
        setGrades(results);
      }}
      className="button is-primary"
    />
  );

  const nextBtn = (
    <input
      type="button"
      className="button is-primary"
      value="Next Lesson"
      onClick={() => {
        setNextLesson(true);
      }}
    />
  );

  return (
    <>
      {!nextButton && finishButton}
      {nextButton && nextBtn}
    </>
  );
}

export default TableButtonView;
