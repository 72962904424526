// import Header from "../layout/Header";
import Navigation from "../layout/Navigation";
//import Aside from "../layout/Aside";
import Main from "../layout/Main";
import Footer from "../layout/Footer";

function MainTemplate(props) {
  return (
    <>
      {/* <Header /> */}
      <Navigation />
      {/* <Aside content={props.aside} /> */}
      <Main content={props.main} />
      <Footer />
    </>
  );
}

export default MainTemplate;
