import React from "react";
// import Modal from "../views/Modal";
// import Signup from "../views/Signup";
// import Login from "../views/Login";
// import { UserContext } from "../../App";
import yticon from "../../images/youtube_icon.png";
import tticon from "../../images/tiktok_icon.png";
import inicon from "../../images/instagram_icon.png";
import xicon from "../../images/x_icon.png";

document.addEventListener("DOMContentLoaded", () => {
  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(
    document.querySelectorAll(".navbar-burger"),
    0
  );

  // Add a click event on each of them
  $navbarBurgers.forEach((el) => {
    el.addEventListener("click", () => {
      // Get the target from the "data-target" attribute
      const target = el.dataset.target;
      const $target = document.getElementById(target);

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      el.classList.toggle("is-active");
      $target.classList.toggle("is-active");
    });
  });
});

function Navigation() {
  // const [modalContent, setModalContent] = useState();

  // const isLoggedIn = useContext(UserContext);

  // const loggedOut = (
  //   <div className={`buttons ${isLoggedIn !== undefined ? null : "is-hidden"}`}>
  //     <button
  //       className="button is-primary is-light js-modal-trigger"
  //       data-target="lt-modal"
  //       onClick={() => {
  //         setModalContent(<Signup />);
  //       }}
  //     >
  //       <strong>Sign up</strong>
  //     </button>
  //     <button
  //       className="button is-primary is-light js-modal-trigger"
  //       data-target="lt-modal"
  //       onClick={() => {
  //         setModalContent(<Login />);
  //       }}
  //     >
  //       Log in
  //     </button>
  //   </div>
  // );

  // const loggedIn = (
  //   <div className={`buttons ${isLoggedIn !== undefined ? null : "is-hidden"}`}>
  //     <button
  //       className="button is-primary is-light js-modal-trigger"
  //       data-target="lt-modal"
  //       onClick={() => {
  //         setModalContent(<Signup />);
  //       }}
  //     >
  //       <strong>Sign out</strong>
  //     </button>
  //   </div>
  // );

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="./">
          <img src="ltlogo.svg" alt="logo" />
        </a>
        <span
          role="button"
          className="navbar-burger"
          data-target="navMenu"
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </span>
      </div>
      <div className="navbar-menu" id="navMenu">
        <div className="navbar-start">
          <div className="navbar-item has-dropdown is-hoverable">
            <a href="." className="navbar-link">
              Social Links
            </a>
            <div className="navbar-dropdown">
              <a
                href="https://www.youtube.com/@lingotable"
                className="navbar-item"
                target="_blank"
                rel="noreferrer"
              >
                Youtube <img src={yticon} alt="Youtube" />
              </a>
              <a
                href="https://www.tiktok.com/@lingotable"
                className="navbar-item"
                target="_blank"
                rel="noreferrer"
              >
                TikTok <img src={tticon} alt="TikTok" />
              </a>
              <a
                href="https://www.instagram.com/lingo_table/"
                className="navbar-item"
                target="_blank"
                rel="noreferrer"
              >
                Instagram <img src={inicon} alt="Instagram" />
              </a>
              <a
                href="https://x.com/lingotable"
                className="navbar-item"
                target="_blank"
                rel="noreferrer"
              >
                X <img src={xicon} alt="X" />
              </a>
            </div>
          </div>
        </div>
        <div className="navbar-end">
          {/* <div className="navbar-item">
            {isLoggedIn === true ? loggedIn : loggedOut}
          </div> */}
        </div>
      </div>
      {/* <Modal setModalContent={setModalContent} modalContent={modalContent} /> */}
    </nav>
  );
}

export default Navigation;
