import { useEffect, useState, createContext } from "react";
import { getLesson } from "../../functions/Table";
import RowView from "./RowView";

export const TableViewContext = createContext();

function TableView(props) {
  const [nextLesson, setNextLesson] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lesson, setLesson] = useState(true);
  const [grades, setGrades] = useState(false);

  useEffect(() => {
    setLoading(true);
    getLesson(props.topics, props.topic)
      .then((lesson) => {
        setLesson(lesson);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    if (nextLesson !== false) {
      setGrades(false);
      setNextLesson(false);
    }
  }, [nextLesson, props.topic, props.topics]);

  const table = (
    <div className="table-container">
      <table className="table table is-fullwidth is-striped">
        <caption>Click "Finish" even if you don't know the answer</caption>
        <TableViewContext.Provider
          value={[setNextLesson, setGrades, props.language]}
        >
          <RowView title={lesson.lesson} rows={lesson.rows} />
        </TableViewContext.Provider>
      </table>
    </div>
  );

  const noTable = <p>Sorry, no exercise was found for this topic.</p>;

  const finalAnswer = () => {
    let answers = [];
    grades.forEach((element) => {
      if (!element.correct) {
        answers.push(element);
      }
    });

    function formatAnswer(source) {
      if (Array.isArray(source)) {
        source = source.join(", ");
      }
      return source;
    }

    if (answers.length > 0) {
      let finalAnswer = answers.map((element, index) => (
        <li key={index}>
          {element.prop}: <b>{formatAnswer(element.answer)}</b>
        </li>
      ));

      finalAnswer = (
        <>
          <h4>{props.language === "es" ? "Incorrect." : "Incorrecto."}</h4>
          <ul>{finalAnswer}</ul>
        </>
      );

      return finalAnswer;
    } else {
      let finalAnswer = (
        <>
          <h4>{props.language === "es" ? "Correct!" : "Correcto!"}</h4>
        </>
      );

      return finalAnswer;
    }
  };

  const resultPanel = (
    <div
      className={`notification ${
        grades
          ? grades.some((el) => el.correct === false)
            ? "is-danger is-light"
            : "is-success is-light"
          : null
      }`}
    >
      {grades ? finalAnswer() : null}
    </div>
  );

  return (
    <>
      {lesson.rows !== undefined && loading === false && table}
      {lesson.rows === undefined && loading === false && noTable}
      {grades && resultPanel}
      {loading === true && "Loading..."}
    </>
  );
}

export default TableView;
