import { useState, createContext, useEffect } from "react";
import MainTemplate from "../templates/MainTemplate";
import TableView from "../views/TableView";

export const MainPageContext = createContext();

function MainPage() {
  const [menuOptions, setMenuOptions] = useState();
  const [language, setLanguage] = useState({ from: "en", to: "es" });
  const [topics, setTopics] = useState();
  const [topic, setTopic] = useState();
  const [subtopic, setSubTopic] = useState();

  useEffect(() => {
    let options = [];
    let topics;
    import(`../../json/${language.to}Topics.json`)
      .then((_topics) => {
        let x = 0;
        topics = _topics;
        while (x < _topics.length) {
          options[x] = [
            <h2 key={x}>{_topics[x].title}</h2>,
            topics[x].data.map(({ topic, subtopics }, index) => (
              <button
                key={index}
                className="button mr-5 mb-5 is-primary is-rounded is-medium"
                onClick={() => {
                  setSubTopic({ topic, subtopics });
                  setTopic();
                }}
              >
                {topic}
              </button>
            )),
          ];
          x++;
        }
      })
      .finally(() => {
        setTopics(topics);
        setMenuOptions(options);
      })
      .catch(() => {});
  }, [language]);

  useEffect(() => {}, []);

  const subTopicOptions =
    subtopic && topic === undefined ? (
      <>
        <h1 className="title">
          {language &&
            `${
              language.to === "es"
                ? "Select an area to practice"
                : language.to === "en"
                ? "Seleccione un área para practicar"
                : null
            }`}
        </h1>
        {subtopic.subtopics.map(({ file, subtopic }, index) => (
          <button
            key={index}
            className="button mr-5 mb-5 is-primary is-rounded"
            onClick={() => {
              topicChangeHandler({ file, subtopic });
            }}
          >
            {subtopic}
          </button>
        ))}
      </>
    ) : null;

  const selectedTopic = topic ? (
    <div>
      <TableView topics={topics} topic={topic.file} language={language.to} />
    </div>
  ) : null;

  const panel = (
    <div className="notification">
      {subtopic === undefined ? (
        <h1>
          {language &&
            `${
              language.to === "es"
                ? "Practicing Spanish 🇪🇸 💃🏻"
                : language.to === "en"
                ? "Praticando Inglés 🇺🇸 🦅"
                : null
            }`}
        </h1>
      ) : (
        <h1>
          {subtopic.topic}
          {topic !== undefined
            ? topic.subtopic
              ? " > " + topic.subtopic
              : null
            : null}
        </h1>
      )}
    </div>
  );

  function languageChangeHandler(language) {
    setLanguage(language);
    setSubTopic(undefined);
    setTopic(undefined);
  }

  function topicChangeHandler({ subtopic, file }) {
    setTopic({ subtopic, file });
  }

  // const ad = (
  //   <>
  //     <script
  //       async
  //       src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8743590234078538"
  //       crossOrigin="anonymous"
  //     ></script>
  //     <ins
  //       className="adsbygoogle"
  //       style={{ display: "block" }}
  //       data-ad-client="ca-pub-8743590234078538"
  //       data-ad-slot="4376995564"
  //       data-ad-format="auto"
  //       data-full-width-responsive="true"
  //     ></ins>
  //     <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
  //   </>
  // );

  const mainContent = (
    <>
      {menuOptions && (
        <section className="section">
          <div className="card">
            <div className="card-header">
              <div className="card-header-title">
                <div class="select ml-2">
                  <select
                    onChange={(e) => {
                      let value = e.currentTarget.value;
                      languageChangeHandler({ from: "", to: value });
                    }}
                  >
                    <option value="es">English 🇺🇸 to Spanish 🇪🇸</option>
                    <option value="en">Spanish 🇪🇸 to English 🇺🇸</option>
                  </select>
                </div>
              </div>
              <span className="icon has-text-info">
                <i className="fas fa-school" aria-hidden="true"></i>
              </span>
            </div>
            <div className="card-content">
              <div className="content">
                <div className="container">
                  {panel}
                  {/* {ad} */}
                </div>
              </div>
              <div className="content">
                <div className="container">
                  {language && subtopic === undefined && (
                    <>{[...menuOptions]}</>
                  )}
                  {subtopic !== undefined && (
                    <>
                      <button
                        className="button mr-1 mb-1 is-info is-rounded is-light"
                        onClick={() => {
                          if (topic !== undefined) {
                            setTopic(undefined);
                          } else {
                            setSubTopic(undefined);
                          }
                        }}
                      >
                        {language &&
                          `${
                            language.to === "es"
                              ? "< Go Back"
                              : language.to === "en"
                              ? "< Regresa"
                              : null
                          }`}
                      </button>

                      {subTopicOptions}
                      <hr />
                    </>
                  )}
                </div>
                {topic !== undefined && selectedTopic}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );

  return (
    <MainPageContext.Provider value={setTopic}>
      <MainTemplate main={mainContent} />
    </MainPageContext.Provider>
  );
}

export default MainPage;
