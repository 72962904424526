import { useState, useEffect, useMemo } from "react";
import TableButtonView from "./TableButtonView";
import { initialInputs } from "../../functions/Table";

function RowView(props) {
  const [results, setResults] = useState(false);

  let initialValues = useMemo(() => initialInputs(props.rows), [props.rows]);

  const [inputs, setInputs] = useState(initialValues);

  useEffect(() => {
    setResults(false);
    setInputs(initialValues);
  }, [initialValues]);

  const lessonRows = props.rows.map((row, index) => (
    <tr key={row[0]} colSpan="2">
      <td>
        <strong>{index + 1}. </strong>
        {row[0].replace(/_/g, " ")}
      </td>
      <td>
        <input
          className={`input`}
          name={row[0]}
          onKeyDownCapture={(event) => {
            if (event.key === "Enter")
              document.getElementById("finishBtn").click();
          }}
          onChange={(event) => {
            let prop = event.target.name;
            let value = event.target.value;
            setInputs((previousState) => {
              return Object.assign(previousState, { [prop]: value });
            });
          }}
          {...(results && { disabled: true })}
        />
      </td>
    </tr>
  ));

  return (
    <>
      <colgroup>
        <col span="1" style={{ width: "20%" }} />
        <col span="2" style={{ width: "70%" }} />
      </colgroup>
      <thead>
        <tr>
          <th colSpan="2">{props.title}</th>
        </tr>
      </thead>
      <tbody>{lessonRows}</tbody>
      <tfoot>
        <tr className="has-text-centered">
          <td
            colSpan="2"
            style={{
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <TableButtonView
              inputs={inputs}
              rows={props.rows}
              setResults={setResults}
            />
          </td>
        </tr>
      </tfoot>
    </>
  );
}

export default RowView;
